<script setup>
import { mdiAccountMultiple } from '@mdi/js'
import MainSection from '@/components/MainSection.vue'
import ExchangesTable from '@/components/ExchangeKeys/ExchangeKeysTable.vue'
import CardComponent from '@/components/CardComponent.vue'
import HeroBarExchanges from '@/components/ExchangeKeys/HeroBarExchangeKeys.vue'
import UserService from "@/services/user.service";
import {ref} from "vue";
import StdJsonTable from "@/components/StdJsonTable.vue";
import TitledSection from "@/components/TitledSection.vue";
import Level from "@/components/Level.vue";
import JbButton from "@/components/JbButton.vue";
import TickersJsonTable from "@/components/Ticker/TickersJsonTable.vue";

let records = ref([]);

function fetch() {
  UserService.getTickerPairs().then((res) => {
    records.value.push(...res.data)
  })
}
fetch();
</script>

<template>
  <section
    class="bg-white border-t border-b p-6 dark:bg-gray-900/70 dark:border-gray-900 dark:text-white"
  >
    <level>
      <h1 class="text-3xl font-semibold leading-tight">
        Tickers
      </h1>
      <jb-button
        :to="{ name: 'ticker-setting-pair-create'}"
        small
        label="Add new ticker"
      />
    </level>
  </section>

  <main-section>

    <card-component
      class="mb-6"
      title="Tickers"
      :icon="mdiAccountMultiple"
      has-table
    >

      <tickers-json-table :records=records :per-page="10000" />
    </card-component>
  </main-section>
</template>
