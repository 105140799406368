import axios from 'axios'
import authHeader from './auth-header'
const API_URL = process.env.VUE_APP_API_HOST
class UserService {
  getUserBots () {
    return axios.get(API_URL + 'bot', { headers: authHeader() })
  }

  getBotsByUserId (userId) {
    return axios.get(API_URL + 'bot/by-user/' + userId, { headers: authHeader() })
  }

  updateBotStatus (botId, newStatus) {
    return axios.post(API_URL + 'bot/' + botId + (newStatus ? '/enable' : '/disable'), {}, { headers: authHeader() })
  }

  createBot (payload) {
    return axios.post(API_URL + 'bot/', payload, { headers: authHeader() })
  }

  createExchangeConnection (payload) {
    return axios.post(API_URL + 'exchange-connection', payload, { headers: authHeader() })
  }

  createUser (payload) {
    return axios.post(API_URL + 'registration', payload, { headers: authHeader() })
  }
  extendSubscription (userUUID, days, reason) {
    return axios.post(API_URL + 'subscription', {
      user_uuid: userUUID,
      days: days,
      reason: reason
    }, { headers: authHeader() })
  }


  addTickerPair (payload) {
    return axios.post(API_URL + 'ticker-pair-setting', payload, { headers: authHeader() })
  }

  getExchangeKeys (user_uuid) {
    const params = {};
    if (user_uuid) {
      params.user_uuid = user_uuid
    }
    return axios.get(API_URL + 'exchange-connection' + '?' + new URLSearchParams(params).toString(), { headers: authHeader() })
  }

  getExchanges () {
    return axios.get(API_URL + 'admin/exchanges', { headers: authHeader() })
  }

  updateExchangeStatus (exchangeId, newStatus) {
    return axios.post(API_URL + 'admin/exchanges/' + exchangeId + '/status', {"status": newStatus}, { headers: authHeader() })
  }
  getUsers () {
    return axios.get(API_URL + 'users', { headers: authHeader() })
  }
  getUser (id) {
    return axios.get(API_URL + 'users/' + id, { headers: authHeader() })
  }

  getDeals (botId) {
    return axios.get(API_URL + 'deals/' + botId, { headers: authHeader() })
  }

  closeDeal (dealId) {
    return axios.post(API_URL + 'deals/' + dealId + '/close', {}, { headers: authHeader() })
  }

  getOrders (dealId) {
    return axios.get(API_URL + 'orders/' + dealId, { headers: authHeader() })
  }

  getBalance (exchangeId) {
    return axios.get(API_URL + 'balance?exchange_id=' + exchangeId, { headers: authHeader() })
  }
  getSignals () {
    return axios.get(API_URL + 'signal', { headers: authHeader() })
  }
  getTickerConnections () {
    return axios.get(API_URL + 'ticker/connections', { headers: authHeader() })
  }
  getTickerPairs () {
    return axios.get(API_URL + 'ticker-pair-setting', { headers: authHeader() })
  }
  stopTickerConnections () {
    return axios.post(API_URL + 'ticker/stop', {}, { headers: authHeader() })
  }

  startTickerConnections () {
    return axios.post(API_URL + 'ticker/start', {}, { headers: authHeader() })
  }

  checkCredentials (data) {
    return axios.post(API_URL + 'exchange-connection/test', data, { headers: authHeader() })
  }

  deleteTicker (id) {
    return axios.delete(API_URL + 'ticker-pair-setting/' + id, { headers: authHeader() })
  }
}
export default new UserService()
