import {
    mdiDesktopMac, mdiFaceMan, mdiKey, mdiCash,
    mdiRobot, mdiAbTesting, mdiGraph, mdiGraphql, mdiChartHistogram
} from '@mdi/js'

export default [
  'General',
  [
    {
      to: '/dashboard',
      icon: mdiDesktopMac,
      label: 'Dashboard'
    }
  ],
  [
    {
      to: '/users',
      label: 'Users',
      icon: mdiFaceMan
    }
  ],
  [
    {
      to: '/payments',
      label: 'Payments',
      icon: mdiCash
    }
  ],
  [
    {
      to: '/bots',
      label: 'Bots',
      icon: mdiRobot
    }
  ],
  [
    {
      to: '/exchange-keys',
      label: 'Exchange Keys',
      icon: mdiKey
    }
  ],
  [
    {
      to: '/exchanges',
      label: 'Exchanges',
      icon: mdiKey
    }
  ],
  [
    {
      to: '/signals',
      label: 'Signals',
      icon: mdiKey
    }
  ],
  [
    {
      to: '/tickers',
      label: 'Tickers',
      icon: mdiKey
    },
    {
      to: '/ticker-settings',
      label: 'Ticker settings',
      icon: mdiKey
    }
  ],
  [
    {
      to: '/back-testing',
      label: 'Back-testing',
      icon: mdiChartHistogram
    }
  ],
]
