<script setup>
import { computed, reactive } from 'vue'
import { mdiBallot } from '@mdi/js'
import MainSection from '@/components/MainSection.vue'
import CardComponent from '@/components/CardComponent.vue'
import Field from '@/components/Field.vue'
import Control from '@/components/Control.vue'
import Divider from '@/components/Divider.vue'
import JbButton from '@/components/JbButton.vue'
import JbButtons from '@/components/JbButtons.vue'
import UserService from '@/services/user.service'
import { useMainStore } from '@/stores/main'
import router from '@/router'

const mainStore = useMainStore()

const exchanges = computed(() => mainStore.exchanges)

const exchangesList = computed(() => {
  const exchangesList = []
  console.log('refresh options', exchanges.value)
  for (let i = 0; i < exchanges.value.length; i++) {
    console.log(
      'push'
    )
    exchangesList.push({ id: exchanges.value[i]._id, label: exchanges.value[i].type + ' ' + exchanges.value[i].api_key })
  }

  return exchangesList
})

UserService.getExchangeKeys().then((res) => {
  mainStore.exchanges = (res.data)
})
const form = reactive({
  title: '',
  exchange_id: exchanges.value ? exchanges.value[0] : null,
  first_order_amount: 10,
  take_profit: 3,
  take_profit_type: 'percentage',
  enable_safety_orders: false,
  safety_order_amount: null,
  max_safety_orders: null,
  stop_lose_percentage: null,
  safety_order_percentage: null,
  active_deals: 5
})

const submit = () => {
  UserService.createBot({
    title: form.title,
    exchange_connection_id: form.exchange_id.id,
    first_order_amount: form.first_order_amount,
    take_profit: form.take_profit,
    take_profit_type: form.take_profit_type,
    enable_safety_orders: form.enable_safety_orders,
    safety_order_amount: form.safety_order_amount,
    max_safety_orders: form.max_safety_orders,
    stop_lose_percentage: form.stop_lose_percentage,
    safety_order_percentage: form.safety_order_percentage,
    active_deals: form.active_deals
  }).then(() => {
    router.push({ name: 'bots' })
  })
}
</script>

<template>
  <main-section>
    <card-component
      title="New bots"
      :icon="mdiBallot"
      form
      @submit.prevent="submit"
    >
      <field label="Title">
        <control v-model="form.title" />
      </field>

      <field label="Exchange">
        <control
          v-model="form.exchange_id"
          :options="exchangesList"
        />
      </field>

      <field label="First order amount">
        <control v-model="form.first_order_amount" />
      </field>
      <field label="Take profit">
        <control v-model="form.take_profit" />
      </field>
      <field label="Take profit type">
        <control
          v-model="form.take_profit_type"
          :options="['percentage']"
        />
      </field>
      <field label="Enable safety orders">
        <control
          v-model="form.enable_safety_orders"
          :options="[true, false]"
        />
      </field>
      <field label="Safety order amount">
        <control v-model="form.safety_order_amount" />
      </field>
      <field label="Safety order percentage">
        <control v-model="form.safety_order_percentage" />
      </field>
      <field label="Max safety orders">
        <control v-model="form.max_safety_orders" />
      </field>
      <field label="Stop lose percentage">
        <control v-model="form.stop_lose_percentage" />
      </field>
      <field label="Active deals">
        <control v-model="form.active_deals" />
      </field>

      <divider />

      <jb-buttons>
        <jb-button
          type="submit"
          color="info"
          label="Submit"
        />
      </jb-buttons>
    </card-component>
  </main-section>
</template>
