<script setup>
import { mdiAccountMultiple } from '@mdi/js'
import MainSection from '@/components/MainSection.vue'
import CardComponent from '@/components/CardComponent.vue'
import UserBotsTable from '@/components/Users/UserBotsTable'
import {useMainStore} from "@/stores/main";
import UserService from "@/services/user.service";
import ExchangesTable from "@/components/ExchangeKeys/ExchangeKeysTable.vue";
import {useRoute} from "vue-router";
import {ref} from "vue";
import StdJsonTable from "@/components/StdJsonTable.vue";


const mainStore = useMainStore()
const route = useRoute()

const exchangeId = route.params.exchange_id;
let assets = ref([]);

UserService.getBalance(exchangeId).then((res) => {
  assets.value.push(...res.data.assets)
})

</script>

<template>
  <main-section>
    <div
      class="inline-grid grid-cols-1"
    >
      <card-component
        title="Balance"
        :icon="mdiAccountMultiple"
        has-table
      >
        <std-json-table checkable :records="assets" />
      </card-component>
    </div>
  </main-section>
</template>
