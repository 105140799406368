<script setup>
import { mdiAccountMultiple } from '@mdi/js'
import MainSection from '@/components/MainSection.vue'
import CardComponent from '@/components/CardComponent.vue'
import UserBotsTable from '@/components/Users/UserBotsTable'
import {useMainStore} from "@/stores/main";
import UserService from "@/services/user.service";
import ExchangesTable from "@/components/ExchangeKeys/ExchangeKeysTable.vue";
import {useRoute} from "vue-router";
import {reactive, ref} from "vue";
import ExtendSubscriptionModal from "@/components/Users/ExtendSubscriptionModal.vue";
import JbButton from "@/components/JbButton.vue";


const mainStore = useMainStore()
const route = useRoute()

const states = reactive({
  extendSubscription: false,
})

const userData = reactive({
  userInfo: null
})

const userId = route.params.user_id;
let exchanges = ref([]);
UserService.getUser(userId).then(async (res) => {

  userData.userInfo = res.data;
  UserService.getExchangeKeys(res.data.uuid).then((res) => {
    exchanges.value.push(...res.data)
  })

});

</script>

<template>

  <main-section>

<table>
  <tr>
    <th>Key</th>
    <th>Value</th>
  </tr>
  <tr
    v-for="(value, key) in userData.userInfo"
    :key="key"
    :data-label="key"
  >
    <td>{{key}}</td>
    <td><span v-if="typeof value !== 'object' ">{{ value }}</span></td>
    <td><pre v-if="typeof value === 'object' ">{{ JSON.stringify((value), null, 2) }}</pre></td>
  </tr>
</table>

    <jb-button
      label="Extend subscription"
      color="info"
      @click="() => { states.extendSubscription = true; }"
    />
    <br>
    <div
      class="row"
    >
      <card-component
        title="Bots"
        :icon="mdiAccountMultiple"
        has-table
      >
        <user-bots-table checkable />
      </card-component>
    </div>
    <br>
    <div class="row">
      <card-component
        title="Accounts"
        :icon="mdiAccountMultiple"
        has-table
      >
        <exchanges-table checkable :records="exchanges" />
      </card-component>
    </div>
    <div  v-if="userData.userInfo">
      <extend-subscription-modal :user_uuid="userData.userInfo.uuid" :active_modal="states.extendSubscription"></extend-subscription-modal>
    </div>
  </main-section>
</template>
